import Exception from '@/exceptions/Exception'

export default class SiretAlreadyUsedByYouException extends Exception {
	/**
	 * @param {string} siret
	 */
	constructor (siret) {
		const message = `SIRET "${siret}" already used by you.`
		super(message, 'SiretAlreadyUsedByYouException')
	}
}
