// ============================================================================
// CustomersManagerService
// -----------------------
// CustomersManager module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/CustomersManagerApi'
import { Bus as BUS, Events as EVENTS } from '@/events/CustomersManager/CustomersManagerEvents'

import AppService from '@/services/AppService'
import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

import ThemesService from '@/services/Themes/ThemesService'
import SettingsService from '@/services/Settings/SettingsService'
import ThemeUserService from '@/services/Themes/ThemeUserService'
import ApplicationUserService from  '@/services/Applications/ApplicationUserService'

import TeamManagerApi from '@/apis/TeamManagerApi'

import NoDataFoundFromSiret from '@/exceptions/CustomersManager/NoDataFoundFromSiretException'
import SiretAlreadyUsedByAnotherException from '@/exceptions/CustomersManager/SiretAlreadyUsedByAnotherException'
import SiretAlreadyUsedByYouException from '@/exceptions/CustomersManager/SiretAlreadyUsedByYouException'
import NotFoundException from '@/exceptions/NotFoundException'

import DefaultInterceptor from '@/plugins/axios/DefaultInterceptor'

// ---------
// Internals
// ---------
const Private = {
	listUsers: function (accountingFirmId, vendorId, filters) {
		const data = {
			accountants: filters.accountants,
			accounting_firm_id: accountingFirmId,
			company_id: vendorId
		}

		const callAnswer = API.listUsers(data)

		return {
			cancelToken: callAnswer.cancelToken,
			promise: callAnswer.promise.then(res => res?.data?.data)
		}
	},
	addCompany: function (company) {
		let companies = store.state.company.list
		companies.push(company)
		AppService.goTo({
			vendor_id: company.id
		})
	},
	removeCompany: function (vendorId) {
		let companies = store.state.company.list
		const index = companies.findIndex(company => company.id === vendorId)
		if (index === -1) {
			throw new NotFoundException(`No company was found with the id "${vendorId}"`)
		}
		companies.splice(index, 1)
		if (vendorId === store.state.company.selected.id) {
			if (companies.length > 0) {
				AppService.goTo({
					params: {
						vendor_id: companies[0]?.id
					}
				})
			} else {
				AppService.goTo('customers')
			}
		}
	},
	isValidEmail: function (email = null) {
		let result = false
		if (email) {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			result = pattern.test(email)
		}
		return result
	},
	isValidPostcode: function (postcode = null) {
		let result = false
		if (postcode) {
			const pattern = /^\d{5}$/
			result = pattern.test(postcode)
		}
		return result
	},
	isValidSIREN: function (siren = null) {
		let result = false
		if (siren) {
			const pattern = /^\d{9}$/
			result = pattern.test(siren)
		}
		return result
	},
	isValidSIRET: function (siret = null) {
		let result = false
		if (siret) {
			const pattern = /^\d{14}$/
			result = pattern.test(siret)
		}
		if (result) {
			let sum = 0
			for (let i = 0; i < 14; i++) {
				const rank = 13 - i
				let num = 0
				if (i % 2) {
					num = parseInt(siret.charAt(rank)) * 2
					if (num > 9) {
						num = num - 9
					}
				} else {
					num = parseInt(siret.charAt(rank))
				}
				sum = sum + num
			}
			result = sum % 10 === 0
		}
		return result
	}
}
// -------
// Exports
// -------
export default {
	compareValues: AppService.compareValues,
	copyToClipboard: AppService.copyToClipboard,
	getAccountingFirmsOfGroup: function () {
		const accountingFirmId = store.state.accountingFirm.selected.id
		return API.getAccountingFirmsOfGroup(accountingFirmId).then(res => res?.data?.data)
	},
	addAddress: function (accountingFirmId, vendorId, data) {
		return API.createAddress(accountingFirmId, vendorId, data).then(res => res?.data?.data)
	},
	addBankAccount: function (accountingFirmId, vendorId, data) {
		return API.createBankAccount(accountingFirmId, vendorId, data).then(res => res?.data?.data)
	},
	addToFavorites: function (company) {
		return API.updateCompanyFavorite(company.id, { favorite: true })
	},
	addUser: function (accountingFirmId, vendorId, data) {
		return API.createUser(accountingFirmId, vendorId, data, { show_error: false })
			.then(res => res?.data?.data)
			.catch(err => {
				const errorCode = err?.response?.data?.error?.code

				if (errorCode) {
					let message
					if (window.vueInstance.$te(`api.errors.${errorCode}`)) {
						message = window.vueInstance.$t(`api.errors.${errorCode}`)
					} else {
						message = window.vueInstance.$t(`customer.users.errors.${errorCode}`)
					}
					AppEventBus.emit(AppEvents.SNACKBAR_ERROR, message)
					return Promise.reject(err)
				} else {
					return DefaultInterceptor.onResponseError(err)
				}
			})
	},
	archiveCustomer: function (company) {
		const accountingFirmId = store.state.accountingFirm.selected.id
		const vendorId = company.id
		return API.archiveCustomer(accountingFirmId, vendorId).then(() => {
			AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('customer.administration.customer_deactivated'))
			Private.removeCompany(vendorId)
			AppService.goTo('customers')
		})
	},
	restoreCustomer: function (company) {
		const accountingFirmId = store.state.accountingFirm.selected.id
		const vendorId = company.id
		return API.restoreCustomer(accountingFirmId, vendorId).then(companyRes => {
			AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('customer.administration.customer_restored'))
			BUS.emit(EVENTS.CUSTOMER_RESTORED)
			Private.addCompany(companyRes)
		})
	},
	deleteVendor: function (vendor) {
		const accountingFirmId = store.state.accountingFirm.selected.id

		return API.deleteVendor(accountingFirmId, vendor.id).then(() => {
			AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('customer.administration.customer_deleted'))
			BUS.emit(EVENTS.VENDOR_DELETED, vendor)
		})
	},
	getArchivedCustomers: function (accountingFirmId) {
		return API.getArchivedCustomers(accountingFirmId)
	},
	transferCustomer: function (vendorId, newAccountingFirmId) {
		const accountingFirmId = store.state.accountingFirm.selected.id
		return API.transferCustomer(accountingFirmId, vendorId, newAccountingFirmId).then(() => {
			AppEventBus.emit(AppEvents.SNACKBAR_SUCCESS, window.vueInstance.$t('customer.administration.customer_transfered'))
			AppService.goTo('customers')
		})
	},
	createCustomer: function (accountingFirmId, vendorData) {
		return API.createCompany(accountingFirmId, vendorData)
			.then(res => res?.data?.data)
			.then(createdCompany => {
				store.dispatch('company/addCompany', createdCompany)
				AppService.loadCompanyUserRole(createdCompany.id)
				BUS.emit(EVENTS.CUSTOMER_CREATED, createdCompany)
				return createdCompany
			})
			.catch(err => {
				if (err?.response?.data?.error?.code) {
					const errorCode = err.response.data.error.code
					let message
					if (window.vueInstance.$te('api.errors.' + errorCode)) {
						message = window.vueInstance.$t('api.errors.' + errorCode)
					} else {
						message = window.vueInstance.$t(`customers.errors.${errorCode}`)
					}
					AppEventBus.emit(AppEvents.SNACKBAR_ERROR, message)

					return Promise.reject(err)
				} else if (err?.response?.status >= 500) {
					AppEventBus.emit(this.appEvents.SNACKBAR_ERROR, window.vueInstance.$t('customers.messages.company_failed'))
				} else {
					return DefaultInterceptor.onResponseError(err)
				}
			})
	},
	createThemeForCompanyUser: function (vendorId, data) {
		return API.createThemeForCompanyUser(vendorId, data).then(res => res?.data?.data)
	},
	createThemeForAccountant: ThemesService.create,
	deleteAddress: function (accountingFirmId, vendorId, addressId) {
		return API.deleteAddress(accountingFirmId, vendorId, addressId)
	},
	deleteBankAccount: function (accountingFirmId, vendorId, bankAccountId) {
		return API.deleteBankAccount(accountingFirmId, vendorId, bankAccountId)
	},
	deleteUser: function (accountingFirmId, vendorId, userId) {
		return API.deleteUser(accountingFirmId, vendorId, userId)
	},
	deleteThemeForCompanyUser: function (vendorId, themeId) {
		return API.deleteThemeForCompanyUser(vendorId, themeId)
	},
	getAddresses: function (accountingFirmId, vendorId) {
		return API.getAddresses(accountingFirmId, vendorId).then(res => res?.data?.data)
	},
	getApps: function (accountingFirmId, vendorId) {
		return API.getApplications(accountingFirmId, vendorId, { is_for_vendors: true, is_home: true }).then(res => res?.data?.data)
	},
	getBankAccounts: function (accountingFirmId, vendorId) {
		return API.getBankAccounts(accountingFirmId, vendorId).then(res => res?.data?.data)
	},
	getCatalogTreeStructures: function (vendorId) {
		const accountingFirmId = store.state.accountingFirm.selected.id
		return API.getCatalogTreeStructures(accountingFirmId, vendorId).then(res => res?.data?.data)
	},
	listCatalogTreeStructures: function (accountingFirmId, params = {}) {
		return API.listCatalogTreeStructures(accountingFirmId, params)
			.then(response => response.data.data)
	},
	getJobs: function () {
		return API.getJobs()
			.then(response => {
				return response.data.data
			})
	},
	listNafCodes: function () {
		return API.getNAFCodes().then(res => res?.data?.data)
	},
	getRoles: function (accountingFirmId) {
		return API.getRoles(accountingFirmId).then(res => res?.data?.data)
	},
	getSiretData: function (accountingFirmId, siret) {
		return API.getSiretData(
			{
				accounting_firm_id: accountingFirmId,
				siret
			},
			{
				show_error: false
			}
		)
			.then(res => res?.data?.data)
			.catch(error => {
				if (error.response?.data) {
					const {
						error: { code },
						status
					} = error.response.data

					if (status === 404 && code === 'siret.no-data-found') {
						throw new NoDataFoundFromSiret(siret)
					} else if (status === 403 && code === 'siret.already-used-by-you') {
						throw new SiretAlreadyUsedByYouException(siret)
					} else if (status === 403 && code === 'siret.already-used-by-another') {
						throw new SiretAlreadyUsedByAnotherException(siret)
					} else {
						return DefaultInterceptor.onResponseError(error)
					}
				} else {
					return DefaultInterceptor.onResponseError(error)
				}
			})
	},
	getTaxOffices: function (accountingFirmId) {
		return API.getTaxOffices(accountingFirmId).then(res => res?.data?.data)
	},
	getThemesForCompanyUser: function (vendorId) {
		return API.getThemesForCompanyUser(vendorId).then(res => res?.data?.data)
	},
	getThemesForAccountant: ThemesService.get,
	listCatalogThemes: function (catalogTreeStructureId, params = {}) {
		return API.listCatalogThemes(catalogTreeStructureId, params)
			.then(response => response.data.data)
	},
	getACLList: ThemesService.getACLList,
	getUsersOfCompany: SettingsService.getUsers,
	getThemesUser: ThemeUserService.getThemesUser,
	getApplicationsUser: ApplicationUserService.getApplicationsUser,
	deleteThemeForAccountant: ThemesService.destroy,
	getUsers: function (accountingFirmId, vendorId) {
		return API.getUsers(accountingFirmId, vendorId).then(res => res?.data?.data)
	},
	findUserByEmail: function (email) {
		return TeamManagerApi.getUserByEmail(email).then(res => res.data.data)
		.then(users => users?.[0] ?? null)
	},
	getUserByEmail: function (email) {
		return TeamManagerApi.getUserByEmail(email).then(res => res.data.data)
		.then(users => users?.[0] ?? null)
	},
	listClientUsers: function (accountingFirmId, vendorId) {
		return Private.listUsers(accountingFirmId, vendorId, { accountants: false })
	},
	hasAccessToAdministrationModule: function (accountingFirmId) {
		return API.hasAccessToAdministrationModule(accountingFirmId)
			.then(res => res.is_accessible)
			.catch(() => false)
	},
	isValidEmail: Private.isValidEmail,
	isValidPostcode: Private.isValidPostcode,
	isValidSIREN: Private.isValidSIREN,
	isValidSIRET: Private.isValidSIRET,
	findAccountant: function (accountingFirmId, userId) {
		return API.findAccountant(accountingFirmId, userId, { show_error: false })
			.then(response => response.data.data)
			.catch(error => {
				if (error.response.status == 404) {
					return null
				}
				throw error
			})
	},
	loadAccountants: function () {
		const accountingFirmId = store.state.accountingFirm.selected.id
		return API.getAccountants(accountingFirmId).then(res => {
			return store.dispatch('accountingTeam/set', res?.data?.data)
		})
	},
	listAccountants: function (accountingFirmId) {
		return API.getAccountants(accountingFirmId)
			.then(response => response.data.data)
	},
	getFilteredCustomers: function (accountingFirmId, filters) {
		return API.getCustomers(accountingFirmId, { params: filters })
	},
	getCustomers: function (accountingFirmId) {
		return API.getCustomers(accountingFirmId).then(res => {
			const customers = res.customers.sort(AppService.compareValues('company', 'asc'))
			store.dispatch('company/setCompaniesDetails', customers)
			return customers
		})
	},
	openCompanyDetails: function (company) {
		if (!company) {
			return
		}
		AppService.goTo({
			name: 'customer',
			params: {
				vendor_id: company.id
			}
		})
	},
	removeFromFavorites: function (company) {
		return API.updateCompanyFavorite(company.id, { favorite: false })
	},
	selectCompanyById: function (id) {
		const company = store.state.company.list.find(availableCompany => availableCompany.id == id)
		return store.dispatch('company/setCompany', company)
	},
	updateAddress: function (accountingFirmId, vendorId, addressId, data) {
		return API.updateAddress(accountingFirmId, vendorId, addressId, data).then(res => res?.data?.data)
	},
	updateBankAccount: function (accountingFirmId, vendorId, bankAccountId, data) {
		return API.updateBankAccount(accountingFirmId, vendorId, bankAccountId, data).then(res => res?.data?.data)
	},
	updateCompanyDetails: function (accountingFirmId, vendorId, data) {
		return API.updateCompanyDetails(accountingFirmId, vendorId, data)
			.then(res => res?.data?.data)
			.then(async company => {
				if (company.pivot) {
					company.client_code = company.pivot.client_code
				}
				return store.dispatch('company/updateCompany', company).then(() => {
					return company
				})
			})
	},
	updateUser: function (accountingFirmId, vendorId, userId, userData) {
		return API.updateUser(accountingFirmId, vendorId, userId, userData).then(res => res?.data?.data)
	},
	updateThemeForCompanyUser: function (vendorId, themeId, data) {
		return API.updateThemeForCompanyUser(vendorId, themeId, data).then(res => res?.data?.data)
	},
	updateThemeForAccountant: ThemesService.update,
	createAccountingPeriod: function (vendorId, accountingPeriodData) {
		return API.createAccountingPeriod(vendorId, accountingPeriodData).then(res => res.data.data)
	},
	findAccountingPeriod: function (vendorId, accountingPeriodId, params, config) {
		return API.findAccountingPeriod(vendorId, accountingPeriodId, params, config).then(res => res.data.data)
	},
	listAccountingPeriods: function (vendorId, params) {
		return API.getAccountingPeriods(vendorId, params).then(res => res.data.data)
	},
	updateAccountingPeriod: function (vendorId, accountingPeriod) {
		return API.updateAccountingPeriod(vendorId, accountingPeriod.id, accountingPeriod)
			.then(res => res.data.data)
	},
	deleteAccountingPeriod: function (vendorId, accountingPeriodId) {
		return API.deleteAccountingPeriod(vendorId, accountingPeriodId)
	},
	sendMail: function (accountingFirmId, vendorId, userId) {
		return API.inviteUser(accountingFirmId, vendorId, userId)
	},
	resetPasswordSendMail: AppService.resetPasswordSendMail,
	getAutomationsPlugins: function () {
		let result = []
		if (AppService.hasModule('bill-scrapper')) {
			result.push(() => import('@/components/BillScrapper/Automations/BillScrapperAutomationsContainer'))
		}
		return result
	},
	getMagicMails: function (vendorId) {
		return API.getMagicMails(vendorId).then(res => res?.data?.data)
	}
}
