// ============================================================================
// ThemeUserApi
// -------------------
// ThemeUser api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import { RequiredArguments } from '@/helpers/methods'
import URLS from '@/apis/BackEndAPIEndpoints'

// -------
// Exports
// -------
export default {
	vendor: {
        user: {
            themes: {
                read: function (vendorId, userId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    RequiredArguments(vendorId, userId)
                    return Backend.GET(URLS.api.vendors.users.themes.uri, [vendorId, userId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
                },
            }
        }
    }
}