<template>
	<v-layout column fill-height>
		<v-flex scroll-y>
			<v-form :key="company.id" :value="isValid">
				<v-layout column>
					<w-section :sticky="$vuetify.breakpoint.smAndDown" :title="$t('customer.general.general_information') + ' :'">
						<v-layout row wrap>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-text-input
									v-model="copyCompany.company"
									:disabled="!isAccountant"
									:label="$t('customer.general.company')"
									maxlength="191"
									required
									:rules="[rules.noSpecialChar]"
									validate-on-blur
								/>
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-text-input
									v-model="copyCompany.details.corporate_name"
									:disabled="!isAccountant"
									:label="$t('customer.general.corporate_name')"
									maxlength="191"
									validate-on-blur
								/>
							</v-flex>
							<v-flex v-if="isAccountant" px-1 xs12 sm6 md4 lg3 xl2>
								<w-text-input
									v-model="copyCompany.client_code"
									:label="$t('customer.general.client_code')"
									maxlength="191"
									:rules="[rules.noSpecialChar]"
									validate-on-blur
								/>
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-date-picker
									v-model="copyCompany.creation_date"
									:disabled="!isAccountant"
									:label="$t('customer.general.company_creation_date')"
								></w-date-picker>
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-number-input
									v-model="copyCompany.details.employee_number"
									:disabled="userRoles.isGuest"
									:label="$t('customer.general.employee_number')"
									:min="0"
								/>
							</v-flex>
						</v-layout>
					</w-section>
					<w-section :sticky="$vuetify.breakpoint.smAndDown" :title="$t('customer.general.contact_information') + ' :'">
						<v-layout row wrap>
							<v-flex lg4 md6 px-1 sm6 xl2 xs12>
								<w-email-input
									v-model="copyCompany.email"
									:disabled="userRoles.isGuest"
									:label="$t('customer.general.contact_email')"
									maxlength="191"
									:required="false"
								/>
							</v-flex>
							<v-flex px-1 xs12 sm6 md6 lg3 xl2>
								<w-phone-input
									v-model="copyCompany.work_phone"
									:disabled="userRoles.isGuest"
									:label="$t('customer.general.contact_phone')"
									prepend-inner-icon="phone"
									@error="onPhoneError($event)"
								/>
							</v-flex>
						</v-layout>
					</w-section>
					<w-section :sticky="$vuetify.breakpoint.smAndDown" :title="$t('customer.general.legal_information') + ' :'">
						<v-layout row wrap>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-text-input
									v-model="copyCompany.siret"
									:disabled="!isAccountant"
									:label="$t('customer.general.siret')"
									mask="### ### ### #####"
									maxlength="14"
									:rules="[rules.siret]"
								/>
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-text-input
									v-model="copyCompany.siren"
									:disabled="!isAccountant"
									mask="### ### ###"
									maxlength="9"
									:label="$t('customer.general.siren')"
									:rules="[rules.siren]"
								/>
							</v-flex>
							<v-flex px-1 xs12 md8 lg6 xl3>
								<v-autocomplete
									v-model="copyCompany.naf"
									:disabled="!isAccountant"
									:items="nafs"
									item-text="naf_code"
									item-value="id"
									:label="$t('customer.general.naf')"
									:loading="nafLoading"
								></v-autocomplete>
							</v-flex>
							<v-flex v-if="isAccountant" px-1 xs12 sm6 md4 lg3 xl2>
								<w-currency-input v-model="copyCompany.details.capital" :disabled="userRoles.isGuest" />
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-text-input
									v-model="copyCompany.details.rcs_city"
									:disabled="!isAccountant"
									:label="$t('customer.general.rcs')"
									maxlength="191"
								></w-text-input>
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<v-autocomplete
									v-model="copyCompany.details.society_type"
									:disabled="!isAccountant"
									:label="$t('customer.general.society_type')"
									:items="societyTypes"
								></v-autocomplete>
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-date-picker
									v-model="copyCompany.details.closing_date"
									:disabled="!isAccountant"
									:label="$t('customer.general.closing_date')"
								></w-date-picker>
							</v-flex>
						</v-layout>
					</w-section>
					<w-section v-if="isAccountant" :sticky="$vuetify.breakpoint.smAndDown" :title="$t('customer.general.informations_for_the_accounting_firm') + ' :'">
						<v-layout align-center row wrap>
							<v-flex v-if="isAccountant && hasDocumentsModule && copyCompany.catalog_tree_structure_id" px-1 xs12 sm6 md4 lg3 xl2>
								<TreeStructureSelector v-model="copyCompany.catalog_tree_structure_id" />
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-text-input v-model="copyCompany.details.rum" :label="$t('customer.general.rum')" maxlength="191"></w-text-input>
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<w-date-picker
									v-model="copyCompany.details.mission_letter_date"
									:disabled="userRoles.isGuest"
									:label="$t('customer.general.mission_letter_date')"
								></w-date-picker>
							</v-flex>
							<v-flex px-1 xs12 sm6 md4 lg3 xl2>
								<v-switch v-model="copyCompany.details.pick_up_confrere" color="primary" :label="$t('customer.general.pick_up_confrere')"></v-switch>
							</v-flex>
						</v-layout>
					</w-section>
				</v-layout>

				<v-layout v-if="!userRoles.isGuest" align-center justify-end mb-5 pb-4 row shrink>
					<w-btn-save :disabled="!isValid" flat @click="save">
						{{ $t('actions.save') }}
					</w-btn-save>
				</v-layout>
			</v-form>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'
import Validator from '@/mixins/Validator'

function removeTime(date) {
	return date?.substring(0, 10);
}

export default {
	name: 'CustomerDetailsGeneral',
	components: {
		TreeStructureSelector: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/General/TreeStructureSelector')
		})
	},
	mixins: [CustomersManagerModuleGuard, Validator],
	data: function () {
		return {
			copyCompany: { },
			nafs: [],
			isPhoneValid: true,
			nafLoading: false,
			societyTypes: ['SARL', 'SAS', 'SELARL', 'SCI', 'SASU', 'EURL', 'EIRL', 'EI', 'SELAS', 'SNC', 'SCP', 'SCM', 'SC', 'SA', 'Autres']
		}
	},
	computed: {
		...mapState({
			company: state => state.company.selected,
			details: null,
			isAccountant: state => state.auth.isAccountant,
			isAccountantAdmin: state => state.accountingFirm.selected.isAccountantAdmin,
			userRoles: state => state.company.userRoles,
			accountingFirm: state => state.accountingFirm.selected
		}),
		hasDocumentsModule: function () {
			return this.appService.hasModule('documents')
		},
		isValid: function () {
			return (
				typeof this.rules.required(this.copyCompany.company) !== 'string' &&
				typeof this.rules.noSpecialChar(this.copyCompany.company) !== 'string' &&
				typeof this.rules.noSpecialChar(this.copyCompany.client_code) !== 'string' &&
				typeof this.rules.siret(this.copyCompany.siret) !== 'string' &&
				typeof this.rules.siren(this.copyCompany.siren) !== 'string' &&
				typeof this.rules.email(this.copyCompany.email) !== 'string' &&
				this.isPhoneValid
			)
		},
	},
	watch: {
		company: {
			handler: function () {
				this.reset()
			}
		}
	},
	created: function () {
		this.reset()
		this.getNAFCodes()
	},
	methods: {
		getNAFCodes: function () {
			this.nafLoading = true
			this.service
				.listNafCodes()
				.then(res => {
					this.nafs = res
				})
				.finally(() => {
					this.nafLoading = false
				})
		},
		reset: function () {
			this.copyCompany = { ...this.company }
			if (!this.copyCompany.details) {
				this.copyCompany.details = {}
				return;
			}
			this.copyCompany.details = {
				...this.company.details,
				... {
					mission_letter_date: removeTime(this.company.details.mission_letter_date),
					closing_date: removeTime(this.company.details.closing_date)
				}
			}
		},
		save: function () {
			if (!this.isValid) {
				return
			}

			this.service.updateCompanyDetails(this.accountingFirm.id, this.company.id, this.copyCompany).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('client_workspace.details.data_updated'))
			})
		},
		onPhoneError: function (event) {
			this.isPhoneValid = !event;
		}
	}
}
</script>
