import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

const getMagicMails = vendorId => {
	const url = `vendors/${vendorId}/magic-mails`
	return axios.get(url)
}

const getAccountingFirmsOfGroup = accountingFirmId => {
	const url = `customers/${accountingFirmId}/group`
	return axios.get(url)
}

const findAccountant = (accountingFirmId, userId, config) => {
	const url = `accounting-firms/${accountingFirmId}/users/${userId}`
	return axios.get(url, config)
}

const getAccountants = accountingFirmId => {
	const url = `accounting-firms/${accountingFirmId}/accountants`
	return axios.get(url)
}

const createAccountingPeriod = (vendorId, data) => {
	const url = `vendors/${vendorId}/accounting-periods`
	return axios.post(url, data)
}

const findAccountingPeriod = (vendorId, accountingPeriodId, params, config) => {
	const url = `vendors/${vendorId}/accounting-periods/${accountingPeriodId}`
	return axios.get(url, { ...config, params })
}

const getAccountingPeriods = (vendorId, params) => {
	const url = `vendors/${vendorId}/accounting-periods`
	return axios.get(url, { params })
}

const updateAccountingPeriod = (vendorId, accountingPeriodId, data) => {
	const url = `vendors/${vendorId}/accounting-periods/${accountingPeriodId}`
	return axios.patch(url, data)
}

const deleteAccountingPeriod = (vendorId, accountingPeriodId) => {
	const url = `vendors/${vendorId}/accounting-periods/${accountingPeriodId}`
	return axios.delete(url)
}

const createAddress = (accountingFirmId, vendorId, data) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/addresses`
	return axios.post(url, data)
}

const deleteAddress = (accountingFirmId, vendorId, addressId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/addresses/${addressId}`
	return axios.delete(url)
}

const getAddresses = (accountingFirmId, vendorId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/addresses`
	return axios.get(url)
}

const updateAddress = (accountingFirmId, vendorId, addressId, data) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/addresses/${addressId}`
	return axios.patch(url, data)
}

const getApplications = (accountingFirmId, vendorId, params = {}) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/applications`
	return axios.get(url, { params })
}

const createBankAccount = (accountingFirmId, vendorId, data) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/banks`
	return axios.post(url, data)
}

const deleteBankAccount = (accountingFirmId, vendorId, bankAccountId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/banks/${bankAccountId}`
	return axios.delete(url)
}

const getBankAccounts = (accountingFirmId, vendorId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/banks`
	return axios.get(url)
}

const updateBankAccount = (accountingFirmId, vendorId, bankAccountId, data) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/banks/${bankAccountId}`
	return axios.patch(url, data)
}

const listCatalogThemes = (catalogTreeStructureId, params = {}) => {
	const url = `catalog-tree-structures/${catalogTreeStructureId}/catalog-themes`
	return axios.get(url, params)
}

const getCatalogTreeStructures = (accountingFirmId, vendorId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/catalog-tree-structures`
	return axios.get(url)
}

const listCatalogTreeStructures = (accountingFirmId, params) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/catalog-tree-structures`
	return axios.get(url, { params: params })
}

const getCustomers = (accountingFirmId, data) => {
	const url = `customers/${accountingFirmId}/companies`
	return axios.get(url, data).then(res => res.data.data)
}

const getArchivedCustomers = accountingFirmId => {
	const url = `customers/${accountingFirmId}/companies/archive`
	return axios.get(url).then(res => res.data.data)
}

const createCompany = (accountingFirmId, data, config = {}) => {
	const url = `customers/${accountingFirmId}/companies`
	return axios.post(url, data, config)
}

const archiveCustomer = (accountingFirmId, vendorId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/archive`
	return axios.post(url)
}

const restoreCustomer = (accountingFirmId, vendorId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/restore`
	return axios.post(url).then(res => res.data.data)
}

const deleteVendor = (accountingFirmId, vendorId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/delete`
	return axios.post(url)
}

const transferCustomer = (accountingFirmId, vendorId, newAccountingFirmId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/transfer/${newAccountingFirmId}`
	return axios.post(url)
}

const updateCompanyDetails = (accountingFirmId, vendorId, data) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}`
	return axios.patch(url, data)
}

const updateCompanyFavorite = (vendorId, data) => {
	const url = `vendors/${vendorId}/users/me`
	return axios.patch(url, data)
}

const getJobs = () => {
	const url = 'jobs'
	return axios.get(url)
}

const getNAFCodes = () => {
	const url = 'naf-codes'
	return axios.get(url)
}

const getRoles = accountingFirmId => {
	const url = `customers/${accountingFirmId}/roles`
	return axios.get(url)
}

const getSiretData = (data, config = {}) => {
	const params = {
		siret: data.siret
	}
	const url = `customers/${data.accounting_firm_id}/siret`
	return axios.get(url, { ...config, params })
}

const getTaxOffices = accountingFirmId => {
	const url = `customers/${accountingFirmId}/tax-offices`
	return axios.get(url)
}

const createThemeForCompanyUser = (vendorId, data) => {
	const url = `vendors/${vendorId}/themes`
	return axios.post(url, data)
}

const deleteThemeForCompanyUser = (vendorId, themeId) => {
	const url = `vendors/${vendorId}/themes/${themeId}`
	return axios.delete(url)
}

const getThemesForCompanyUser = vendorId => {
	const url = `vendors/${vendorId}/themes`
	return axios.get(url, {
		params: {
			with: ['permission']
		}
	})
}

const updateThemeForCompanyUser = (vendorId, themeId, data) => {
	const url = `vendors/${vendorId}/themes/${themeId}`
	return axios.patch(url, data)
}

const createUser = (accountingFirmId, vendorId, data, config = {}) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/users`
	return axios.post(url, data, config)
}

const deleteUser = (accountingFirmId, vendorId, userId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/users/${userId}`
	return axios.delete(url)
}

const listUsers = (data, config = {}, cancelToken = null) => {
	if (cancelToken) {
		cancelToken.cancel('Request cancelled')
	}
	const newCancelToken = Configurator.CancelToken.source()

	const query = {
		accountants: data.accountants
	}
	const url = `accounting-firms/${data.accounting_firm_id}/vendors/${data.company_id}/users`

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			config,
			params: query
		})
	}
}

const inviteUser = (accountingFirmId, vendorId, userId) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/users/${userId}/send-mail`
	return axios.post(url)
}

const updateUser = (accountingFirmId, vendorId, userId, data) => {
	const url = `customers/${accountingFirmId}/companies/${vendorId}/users/${userId}`
	return axios.patch(url, data)
}

const hasAccessToAdministrationModule = accountingFirmId => {
	const url = `customers/${accountingFirmId}/administration`
	return axios.get(url).then(res => res.data.data)
}

export default {
	getAccountingFirmsOfGroup: getAccountingFirmsOfGroup,
	getAccountants: getAccountants,
	findAccountant,
	createAccountingPeriod,
	findAccountingPeriod,
	getAccountingPeriods,
	updateAccountingPeriod,
	deleteAccountingPeriod,
	createAddress: createAddress,
	deleteAddress: deleteAddress,
	getAddresses: getAddresses,
	updateAddress: updateAddress,
	getApplications: getApplications,
	createBankAccount: createBankAccount,
	deleteBankAccount: deleteBankAccount,
	getBankAccounts: getBankAccounts,
	updateBankAccount: updateBankAccount,
	listCatalogThemes,
	getCatalogTreeStructures: getCatalogTreeStructures,
	listCatalogTreeStructures,
	getCustomers: getCustomers,
	getArchivedCustomers: getArchivedCustomers,
	createCompany: createCompany,
	archiveCustomer: archiveCustomer,
	restoreCustomer: restoreCustomer,
	deleteVendor: deleteVendor,
	transferCustomer: transferCustomer,
	updateCompanyDetails: updateCompanyDetails,
	updateCompanyFavorite: updateCompanyFavorite,
	getJobs: getJobs,
	getNAFCodes: getNAFCodes,
	getRoles: getRoles,
	getSiretData: getSiretData,
	getTaxOffices: getTaxOffices,
	createThemeForCompanyUser: createThemeForCompanyUser,
	deleteThemeForCompanyUser: deleteThemeForCompanyUser,
	getThemesForCompanyUser: getThemesForCompanyUser,
	updateThemeForCompanyUser: updateThemeForCompanyUser,
	createUser: createUser,
	deleteUser: deleteUser,
	listUsers,
	inviteUser: inviteUser,
	updateUser: updateUser,
	hasAccessToAdministrationModule: hasAccessToAdministrationModule,
	getMagicMails: getMagicMails
}
