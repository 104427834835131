// ============================================================================
// ThemeUserService
// -----------------------
// ThemeUser module related services
// ============================================================================
// -------
// Imports
// -------
import { RequiredArguments } from '@/helpers/methods'
import API from '@/apis/ThemeUserApi'
// ---------
// Internals
// ---------
const Private = {
    requests: {
        vendors: {
            users: {
                read: function(vendorId, userId, params = null) {
                    RequiredArguments({ vendorId, userId })
                    return API.vendor.user.themes.read(vendorId, userId, params)
                }
            }
        }
    },
    service: {
        vendors: {
            users: {
                read: function (vendorId, userId, params = null) {
                    RequiredArguments({ vendorId, userId })
                    return Private.requests.vendors.users.read(vendorId, userId, params)
                },
            }
        }
    },
}
// -------
// Exports
// -------
export default {
    getThemesUser: Private.service.vendors.users.read,
}