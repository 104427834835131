import Exception from '@/exceptions/Exception'

export default class NoDataFoundFromSiretException extends Exception {
	/**
	 * @param {string} siret
	 */
	constructor (siret) {
		const message = `No data found from siret "${siret}"`
		super(message, 'NoDataFoundFromSiretException')
	}
}
